<template>
  <a-divider orientation="left">
    Spu List
  </a-divider>

  <a-form :rules="rules" ref="spuForm">
    <a-row :gutter="[10,0]">
      <a-col :xs="24" :lg="8">
        <a-form-item :wrapperCol="{sm:24}">
          <a-select
              ref="catSelect"
              placeholder="Select a category"
              v-model:value="categoryId"
          >
            <template v-if="fetchingCategory" #notFoundContent>
              <a-spin size="small"/>
            </template>
            <a-select-option v-for="category in categories" :value="category.id" :key="category.id">
              {{ category.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :xs="24" :lg="8">
        <a-form-item :wrapperCol="{sm:24}">
          <a-select
              ref="brandSelect"
              placeholder="Select a brand"
              v-model:value="brandId"
          >
            <template v-if="fetchingBrand" #notFoundContent>
              <a-spin size="small"/>
            </template>
            <a-select-option v-for="brand in brands" :value="brand.id" :key="brand.id">
              {{ brand.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col v-if="categoryId && brandId" :xs="24" :lg="8">
        <a-input v-model:value="search" placeholder="Keyword to search" allowClear @keyup.enter="doSearch" @change="searchChange">
          <template #suffix>
            <SearchOutlined style="cursor: pointer;" @click="doSearch"/>
          </template>
        </a-input>
      </a-col>
    </a-row>
  </a-form>

  <a-row>
    <a-form
        ref="addSpuForm"
        :model="addSpuForm"
        :rules="rules"
    >
      <a-col style="display: flex;">
        <a-form-item hasFeedback name="name">
          <a-input v-model:value="addSpuForm.name" placeholder="Input SPU name" type="text"></a-input>
        </a-form-item>
        <a-form-item>
          <a-input v-model:value="addSpuForm.description" placeholder="Input SPU description" type="text"></a-input>
        </a-form-item>
        <a-button class="editable-add-btn" @click="handleAdd" >Add New SPU</a-button>
      </a-col>
    </a-form>
  </a-row>

  <a-table :columns="columns" :data-source="spus" :pagination="pager" rowKey="id" size="small" style="margin-top: 1rem;" @change="onChange">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1}}
    </template>
    <template v-for="col in ['name', 'description']" #[col]="{ text, record }" :key="col">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #is_valid="{ text: is_valid }">
      <a-tag :color="is_valid ? 'green' : 'pink'">
        {{ is_valid ? 'Y' : 'N'}}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a @click="cancel(record.id)" style="margin-left: 1rem;">Cancel</a>
          <!--          <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">-->
          <!--            <a>Cancel</a>-->
          <!--          </a-popconfirm>-->
        </span>
        <span v-else>
<!--          <a @click="edit(record.id)"><EditOutlined/></a>-->
          <a-space>
            <a-button @click="edit(record.id)">Edit Info</a-button>
            <a-button @click="editSpecs(record.id)">Edit Specs</a-button>
            <a-button :type="record.is_valid ? 'default' : 'primary'" @click="toggleEnable(record)"> {{ record.is_valid ? 'Disable': 'Enable' }} </a-button>
          </a-space>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>
import { message } from 'ant-design-vue'
import { getBrandsByCategory, getCategoryList } from '../../api/product'
import { cloneDeep } from 'lodash-es'
import { getSpecStringForDisplay } from '../../utils/specs'
import { addNewSpu, updateSpuInfo, updateSpuStatus, getSpuList } from '../../api/spu'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import {
  SearchOutlined
} from '@ant-design/icons-vue'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '15%',
    slots: { customRender: 'name' }
  },

  {
    title: 'Description',
    dataIndex: 'description',
    width: '15%',
    slots: { customRender: 'description' },
    ellipsis: true
  },
  {
    title: 'Specs',
    dataIndex: 'specString',
    width: '30%',
    ellipsis: true
  },
  {
    title: 'Enable',
    dataIndex: 'is_valid',
    width: '5%',
    slots: { customRender: 'is_valid' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '30%'
  }
]

export default {
  name: 'SpuList',
  components: { SearchOutlined },
  setup () {
    const store = useStore()

    const userId = computed(() => store.state.auth.user.id)
    const spuLoadingParams = computed(() => store.state.transition.spuLoadingParams)

    const cacheLoadingSpuInfo = ({
      pager,
      filters,
      sorters,
      search
    }) => store.dispatch('transition/cacheSpuLoadingParams', {
      pager,
      filters,
      sorters,
      search
    })

    return {
      userId,
      spuLoadingParams,
      cacheLoadingSpuInfo
    }
  },

  created () {
    this.loadCategoryList()
  },

  watch: {
    categoryId (val) {
      this.brandId = undefined
      this.brands = []
      this.spus = []

      if (val) {
        this.loadBrandList()
      }
    },

    brandId (val) {
      console.log('Change brand: ' + val)
      this.spus = []
      if (this.categoryId && val) {
        this.loadSpuList()
      }
    }
  },

  data () {
    return {
      addSpuForm: {
        name: '',
        description: ''
      },
      rules: {
        name: [
          {
            required: true,
            trigger: 'change'
          }
        ]
      },

      grades: [],
      categories: [],
      // categoryId: this.categories?.length > 0 ? this.spuLoadingParams?.filters?.categoryId || undefined : undefined,
      categoryId: this.spuLoadingParams?.filters?.categoryId || undefined,
      fetchingCategory: false,

      brands: [],
      brandId: this.spuLoadingParams?.filters?.brandId || undefined,
      // brandId: this.brands?.length > 0 ? this.spuLoadingParams?.filters?.brandId || undefined : undefined,
      fetchingBrand: false,

      columns,
      spus: [],
      editableData: {},
      showSpecEditor: false,

      search: this.spuLoadingParams?.search || '',
      pager: this.spuLoadingParams?.pager || { pageSize: 10, total: 0, current: 1 },
      filters: this.spuLoadingParams?.filters || {},
      sorters: this.spuLoadingParams?.sorters || []
    }
  },

  methods: {
    resetPager () {
      this.pager = {
        pageSize: 10, total: 0, current: 1
      }
    },

    resetQueryParams () {
      this.categoryId = undefined
      this.brandId = undefined
      this.search = ''
    },

    loadCategoryList () {
      this.fetchingCategory = true
      this.$loading.show('Loading categories...')
      getCategoryList().then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.categories = res.data.categories
          if (this.categoryId) {
            this.loadBrandList()
          }
        } else {
          this.resetQueryParams()
        }
      }).catch(_ => {
        this.$loading.hide()
        message.error('Failed to load categories, please retry')
        this.resetQueryParams()
      }).finally(() => {
        this.fetchingCategory = false
      })
    },
    loadBrandList () {
      this.fetchingBrand = true
      this.$loading.show('Loading brands...')
      getBrandsByCategory({ categoryId: this.categoryId }).then(res => {
        if (res.code === 0) {
          this.brands = res.data.brands
          if (this.brandId) {
            this.loadSpuList()
          }
        }
      }).catch(err => {
        console.log(err)
        message.error('Failed to load brand list, please retry')
      }).finally(() => {
        this.fetchingBrand = false
        this.$loading.hide()
      })
    },
    loadSpuList () {
      const params = {
        pager: { ...this.pager, offset: (this.pager.current - 1) * this.pager.pageSize, limit: this.pager.pageSize },
        filters: { ...this.filters, categoryId: this.categoryId, brandId: this.brandId },
        sorters: this.sorters,
        search: this.search
      }

      this.$loading.show('Loading spu list...')

      getSpuList(params).then(res => {
        if (res.code === 0) {
          this.cacheLoadingSpuInfo(params)
          this.spus = res.data.spus
          this.pager.total = res.data.total
          this.spus.forEach(item => {
            item.specString = getSpecStringForDisplay(item.specs)
          })
        } else {
          this.resetQueryParams()
          this.resetPager()
        }
      }).catch(err => {
        console.log(err)
        message.error('Failed to load spu list, please retry')
        this.resetQueryParams()
        this.resetPager()
      }).finally(() => {
        this.$loading.hide()
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      // console.log(pagination)
      this.pager = pagination

      console.log('Remember transform table filters to filters')
      this.filters = {}

      this.sorters = tableSorters

      this.loadSpuList()
    },
    handleAdd () {
      if (!this.categoryId || !this.brandId) {
        message.error('Please select category and brand first')
        return
      }
      this.$refs.addSpuForm.validate().then(() => {
        const params = {
          user_id: this.userId,
          category_id: this.categoryId,
          brand_id: this.brandId,
          name: this.addSpuForm.name,
          description: this.addSpuForm.description
        }

        addNewSpu(params).then(res => {
          if (res.code === 0) {
            message.success(`Successfully added new SPU ${this.addSpuForm.name}`)
            this.$refs.addSpuForm.resetFields()
            this.resetPager()
            this.loadSpuList()
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.spus.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      updateSpuInfo({
        id: key,
        name: this.editableData[key].name,
        description: this.editableData[key].description
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.spus.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
        } else {
          message.error('Failed to update SPU info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    editSpecs (key) {
      this.$router.push({ path: '/spu_spec', query: { spuId: key } })
    },
    toggleEnable (record) {
      updateSpuStatus({
        id: record.id,
        is_valid: record.is_valid === 1 ? 0 : 1
      }).then(res => {
        if (res.code === 0) {
          record.is_valid = record.is_valid === 1 ? 0 : 1
        } else {
          message.error('Failed to update SPU status')
        }
      }).catch(err => {
        console.log(err)
      })
    },
    doSearch () {
      this.resetPager()
      this.loadSpuList()
    },
    searchChange (e) {
      if (e.type === 'click') {
        this.doSearch()
      }
    }
  }
}
</script>

<style scoped>

</style>
