<template>
  <a-card title="SPU Info" :bordered="false">
    <p>ID: {{ spu?.id }}</p>
    <p>Name: {{ spu?.name }}</p>
    <p>Description: {{ spu?.description }}</p>
    <div style="display: flex;">
      <a-select
          label="Add new specs"
          mode="tags"
          placeholder="Please select specs"
          v-model:value="selectedSpecs"
          style="min-width: 200px">
        <a-select-option v-for="newSpec in filterSpecs" :key="newSpec.id">
          {{ newSpec.name }}
        </a-select-option>
      </a-select>
      <a-button style="margin-left: 1rem;" type="default" @click="addSpecs">Add Specs</a-button>
    </div>
  </a-card>

  <a-table :columns="columns" :data-source="spu ? spu.specs : []" size="middle" rowKey="id">
    <template #index="{ index }">
      {{ index + 1}}
    </template>
    <template #expandedRowRender="{ record }">
      <a-row style="padding-top: 2rem;">
        <a-col :xs="24" :sm="12">
          <SpecEdit :data="record" @saveSpecs="saveSpecValues"></SpecEdit>
        </a-col>
      </a-row>
    </template>
    <template #operation="{ index, record }">
      <div class="editable-row-operations">
        <span>
          <a-button @click="up(record.id, index)">Move Up</a-button>
          <!-- 对于品类强制要求的属性，隐藏remove按钮 -->
          <a-button v-if="!inheritedSpecIds.includes(record.id)" style="margin-left: 1rem;" @click="remove(record.id)">Remove</a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>
import SpecEdit from '../../components/spu/SpecEdit'
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { getSpecList, getSpuInfo, bindSpecWithSpu, swapSpecOrder, removeSpecFromSpu, updateSpuSpecValues } from '../../api/spu'

const columns = [
  { title: 'Spec Name', dataIndex: 'name', key: 'name', width: '20%' },
  { title: 'Values', dataIndex: 'valueString', key: 'valueString', width: '30%' },
  { title: 'Display Order', dataIndex: 'index', key: 'index', slots: { customRender: 'index' }, width: '20%' },
  { title: 'OP', slots: { customRender: 'operation' }, width: '30%' }
]

export default {
  name: 'SpuSpec',
  components: { SpecEdit },

  setup () {
    const route = useRoute()
    const spuId = computed(() => route.query.spuId)

    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)

    const spu = ref({})

    return {
      spu,
      spuId,
      userId
    }
  },

  mounted () {
    this.loadSpu()
  },

  data () {
    return {
      columns,
      selectedSpecs: [],
      filterSpecs: [],
      specs: [],
      inheritedSpecIds: [] // 所属的category必须具备一些spec，这些spec不能被删除
    }
  },

  methods: {
    loadSpu (loadSpecList = true) {
      this.$loading.show('Loading spu spec list...')
      getSpuInfo({
        spu_id: this.spuId
      }).then(res => {
        if (res.code === 0) {
          this.spu = res.data.spu
          this.inheritedSpecIds = res.data.category_specs
          this.spu.specs.forEach(item => {
            item.valueString = item.values.join(' / ')
          })
          if (loadSpecList) {
            this.loadSpecs()
          }
        } else {
          this.$loading.hide()
          message.error('Failed to load spu specs, please try again later')
        }
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    loadSpecs () {
      this.$loading.show('Loading spec list...')
      getSpecList().then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.filterSpecs = []
          const bindList = []
          this.spu.specs.forEach(item => {
            bindList.push(item.id)
          })

          this.specs = res.data.specs
          this.specs.forEach(item => {
            if (!bindList.includes(item.id)) {
              this.filterSpecs.push(item)
            }
          })
        } else {
          this.$loading.hide()
          message.error('Failed to load spec list, please try again later')
        }
      }).catch(err => {
        console.log(err)
        this.$loading.hide()
      })
    },

    addSpecs () {
      if (this.selectedSpecs.length === 0) {
        message.warn('Please select specs to bind')
      }
      bindSpecWithSpu({
        user_id: this.userId,
        spu_id: this.spuId,
        specs: this.selectedSpecs
      }).then(res => {
        if (res.code === 0) {
          this.selectedSpecs.forEach(item => {
            const addedItem = this.specs.filter(iitem => iitem.id === item)[0]
            addedItem.values = []
            this.spu.specs.push(addedItem)
            this.filterSpecs = this.filterSpecs.filter(iitem => iitem.id !== item)
          })
          this.selectedSpecs = []
        } else {
          message.warn('Failed to bind specs to current SPU, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    swapItem (index1, index2) {
      swapSpecOrder([
        {
          spu_id: this.spuId,
          spec_id: this.spu.specs[index1].id,
          sort_order: index2 + 1
        },
        {
          spu_id: this.spuId,
          spec_id: this.spu.specs[index2].id,
          sort_order: index1 + 1
        }
      ]).then(res => {
        if (res.code === 0) {
          this.spu.specs[index1].sort_order = index2 + 1
          this.spu.specs[index2].sort_order = index1 + 1
          this.spu.specs.sort((a, b) => a.sort_order - b.sort_order)
        } else if (res.code === -1) {
          message.error('Invalid arguments! please check data and retry')
        } else {
          message.error('Can\'t update display order, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    up (key) {
      let prevItemIndex = 0
      let currentItemIndex = 0
      for (let i = 0; i < this.spu.specs.length; ++i) {
        currentItemIndex = i
        if (this.spu.specs[i].id === key) {
          break
        } else {
          prevItemIndex = currentItemIndex
        }
      }
      if (currentItemIndex !== 0) {
        this.swapItem(currentItemIndex, prevItemIndex)
      }
    },

    remove (specId) {
      // use pcbid to improve backend performance
      removeSpecFromSpu({
        spu_id: this.spuId,
        spec_id: specId
      }).then(res => {
        if (res.code === 0) {
          this.spu.specs = this.spu.specs.filter(item => item.id !== specId)
          this.filterSpecs.push(this.specs.filter(item => item.id === specId)[0])
        } else {
          message.warn('Failed to remove spec from SPU, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    saveSpecValues (params) {
      const specId = params.id
      params.spu_id = this.spuId
      params.spec_id = specId
      delete params.id
      updateSpuSpecValues(params).then(res => {
        if (res.code === 0) {
          this.spu.specs.filter(item => item.id === specId)[0].valueString = params.values.join(' / ')
          message.info('Spec values updated successfully')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
