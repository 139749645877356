
export function transformSpecs (specs) {
  const formatSpecs = []
  const specList = specs.split(';')
  specList.forEach(item => {
    const values = item.split(':')
    formatSpecs.push({
      id: values[0],
      name: values[1],
      value: values[2]
    })
  })
  return formatSpecs
}

export function toShortReadableString (formatSpecs) {
  const valueList = []
  formatSpecs.forEach(item => {
    valueList.push(item.value)
  })
  return valueList.join('/')
}

export function toLongReadableString (formatSpecs) {
  const valueList = []
  formatSpecs.forEach(item => {
    valueList.push(item.name + ':' + item.value)
  })
  return valueList.join('/')
}

export function getSpecString (specs) {
  const formatSpecs = transformSpecs(specs)
  return toShortReadableString(formatSpecs)
}

// for SPU List
export function getSpecStringForDisplay (specs) {
  const specString = []
  specs.forEach(item => {
    specString.push(`${item.name}: ${item.values.join('/')}`)
  })
  return specString.join('; ')
}
