<template>
  <a-row>
    <a-col>Welcome to staff portal.</a-col>
  </a-row>
</template>

<script>

import { Col, Row } from 'ant-design-vue'

export default {
  name: 'Dashboard',
  components: {
    [Row.name]: Row,
    [Col.name]: Col
  }
}
</script>

<style scoped>

</style>
