<template>
  <a-dropdown placement="bottomRight">
    <span class="hover-panel">
      <a-avatar size="small"></a-avatar>
      <span style="margin-left: 0.4rem;">{{ user.first_name || getUserEmailInfo() }}</span>
    </span>
    <template #overlay>
      <a-menu :selected-keys="[]">
        <a-menu-item key="center" @click="handleToCenter">
          <user-outlined/>
          Dashboard
        </a-menu-item>
        <a-menu-item key="settings" @click="handleToSettings">
          <setting-outlined/>
          Settings
        </a-menu-item>
        <a-menu-divider/>
        <a-menu-item key="logout" @click="handleLogout">
          <logout-outlined/>
          Logout
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>

  <a-dropdown>
    <span class="hover-panel"><global-outlined/></span>
    <template #overlay>
      <a-menu :selected-keys="[]">
        <a-menu-item key="en" @click="setLocale('en-US')">
            <span role="img" class="lang-icon">
              {{ languageIcons['en-US'] }}
            </span>
          <span class="lang-label">English</span>
        </a-menu-item>
<!--        <a-menu-item key="zh" @click="setLocale('zh-CN')">-->
<!--            <span role="img" class="lang-icon">-->
<!--              {{ languageIcons['zh-CN'] }}-->
<!--            </span>-->
<!--          <span class="lang-label">中文</span>-->
<!--        </a-menu-item>-->
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script>
import {
  UserOutlined,
  GlobalOutlined,
  SettingOutlined,
  LogoutOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { Avatar, Dropdown, Menu, Modal } from 'ant-design-vue'
import { getInfoFromEmail } from '@/utils/util'
import { createVNode } from '@vue/runtime-core'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'AvatarDropdown',

  components: {
    UserOutlined,
    GlobalOutlined,
    SettingOutlined,
    LogoutOutlined,
    [Dropdown.name]: Dropdown,
    [Avatar.name]: Avatar,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Menu.Divider.name]: Menu.Divider,
    [Modal.name]: Modal
  },

  setup () {
    const store = useStore()

    const user = computed(() => store.state.auth.user)
    const logout = () => store.dispatch('auth/logout')

    return {
      user,
      logout
    }
  },

  data () {
    return {
      languageIcons: {
        'en-US': '🇺🇸'
        // 'zh-CN': '🇨🇳',
        // 'pt-BR': '🇧🇷'
      }
    }
  },

  methods: {
    setLocale (locale) {
      alert('Not yet started, please be patient')
      console.log(locale)
    },

    getUserEmailInfo () {
      return getInfoFromEmail(this.user.email)
    },

    handleToCenter () {
      this.$router.push({ path: '/home/dashboard' })
    },
    handleToSettings () {
      this.$router.push({ path: '/home/settings' })
    },
    handleLogout () {
      Modal.confirm({
        title: 'Confirm logout',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Are you sure to log out?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => {
          this.logout().then(() => {
            this.$router.push({ path: '/user/login' })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.hover-panel {
  color: black;
  background: rgba(255, 255, 255, 0.85);
  padding: 0 1rem;

  &:hover {
    background: rgba(240, 240, 240, 0.75);
  }
}

.ant-dropdown-menu-item:hover {
  background: #e6f7ff;
}

.lang-label {
  margin-left: 0.4rem;
}

.lang-icon {

}

</style>
