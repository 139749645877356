<template>
  <a-divider orientation="left">
    Grade List
  </a-divider>
  <a-row>
    <a-form
        ref="addGradeForm"
        :model="form"
        :rules="rules"
    >
    <a-col style="display: flex;">
      <a-form-item hasFeedback name="gradeName" required>
        <a-input v-model:value="form.gradeName" placeholder="Input grade name" type="text"></a-input>
      </a-form-item>
      <a-form-item hasFeedback name="gradeDescription">
        <a-input v-model:value="form.gradeDescription" placeholder="Input grade description" type="text"></a-input>
      </a-form-item>
      <a-button class="editable-add-btn" @click="handleAdd" >Add New Grade</a-button>
    </a-col>
    </a-form>
  </a-row>

  <a-table :columns="columns" :data-source="grades" rowKey="id" size="small" style="margin-top: 1rem;"
           @change="onChange">
    <template #name="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].name"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #description="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].description"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a @click="cancel(record.id)">Cancel</a>
        </span>
        <span v-else>
          <a-button @click="edit(record.id)">Edit</a-button>
          <a-button @click="up(record.id)">Move Up</a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>

import {
  addNewGrade,
  getGradeList,
  swapGradeOrder,
  updateGradeInfo
} from '../../api/product'

// import { ref } from 'vue'
// import { ArrowUpOutlined, EditOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '10%',
    slots: { customRender: 'name' }
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '40%',
    slots: { customRender: 'description' }
  },
  {
    title: 'Display Order',
    dataIndex: 'sort_order',
    width: '10%',
    slots: { customRender: 'sort_order' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '40%'
  }
]

export default {
  name: 'GradeList',
  components: {
    // EditOutlined,
    // ArrowUpOutlined
  },
  setup () {
    const store = useStore()
    // eslint-disable-next-line camelcase
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  data () {
    const validateNewGradeName = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input grade')
      } else {
        const item = this.grades.filter(item => item.name === value)
        if (item.length > 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(`${value} already exists`)
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      form: {
        gradeName: '',
        gradeDescription: ''
      },
      rules: {
        gradeName: [
          {
            required: true,
            trigger: 'change',
            validator: validateNewGradeName
          }
        ]
      },
      columns,
      grades: [],
      editableData: {}
    }
  },

  created () {
    this.loadGrades()
  },

  methods: {
    handleAdd () {
      this.$refs.addGradeForm
        .validate().then(() => {
          const params = {
            user_id: this.userId,
            name: this.form.gradeName,
            description: this.form.gradeDescription
          }

          addNewGrade(params).then(res => {
            if (res.code === 0) {
              message.success(`Successfully added new grade ${this.form.gradeName}`)
              this.grades.push(res.data.grade)
              this.$refs.addGradeForm.resetFields()
            }
          }).catch(err => {
            console.log(err)
          })
        })
    },

    loadGrades () {
      this.$loading.show('Loading grades list...')
      getGradeList()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.grades = res.data.grades
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },
    onChange () {
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.grades.filter(item => item.id === key)[0])
    },

    save (key) {
      updateGradeInfo({
        id: key,
        name: this.editableData[key].name,
        description: this.editableData[key].description
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.grades.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
        } else {
          message.error('Failed to change grade info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    cancel (key) {
      delete this.editableData[key]
    },

    swapItem (index1, index2) {
      swapGradeOrder([
        {
          id: this.grades[index1].id,
          sort_order: this.grades[index2].sort_order
        },
        {
          id: this.grades[index2].id,
          sort_order: this.grades[index1].sort_order
        }
      ]).then(res => {
        if (res.code === 0) {
          const idx = this.grades[index1].sort_order
          this.grades[index1].sort_order = this.grades[index2].sort_order
          this.grades[index2].sort_order = idx
          this.grades.sort((a, b) => a.sort_order - b.sort_order)
        } else {
          message.error('Can\'t update display order, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    up (key) {
      let prevItemIndex = 0
      let currentItemIndex = 0
      for (let i = 0; i < this.grades.length; ++i) {
        currentItemIndex = i
        if (this.grades[i].id === key) {
          break
        } else {
          prevItemIndex = currentItemIndex
        }
      }

      if (currentItemIndex !== 0) {
        this.swapItem(currentItemIndex, prevItemIndex)
      }
    }
  }
}
</script>

<style scoped>

.editable-row-operations a {
  margin-right: 8px;
}

</style>
