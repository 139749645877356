import { createLogger, createStore } from 'vuex'

import auth from './modules/auth'
import transition from './modules/transition'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth,
    transition
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
}
)
