<template>
  <a-divider orientation="left">
    Brand List
  </a-divider>
  <a-row>
    <a-form
        ref="addBrandForm"
        :model="form"
        :rules="rules"
    >
    <a-col style="display: flex;">
      <a-form-item hasFeedback name="brandName">
        <a-input v-model:value="form.brandName" placeholder="Input brand name" type="text"></a-input>
      </a-form-item>
      <a-button class="editable-add-btn" @click="handleAdd" >Add Brand</a-button>
    </a-col>
    </a-form>
  </a-row>

  <a-table :columns="columns" :data-source="brands" rowKey="id" size="small" style="margin-top: 1rem;"
           @change="onChange">
    <template #name="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].name"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #is_valid="{ text: is_valid }">
      <a-tag :color="is_valid ? 'green' : 'pink'">
        {{ is_valid ? 'Y' : 'N'}}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a @click="cancel(record.id)">Cancel</a>
          <!--          <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">-->
          <!--            <a>Cancel</a>-->
          <!--          </a-popconfirm>-->
        </span>
        <span v-else>
<!--          <a @click="edit(record.id)"><EditOutlined/></a>-->
          <a-button @click="edit(record.id)">Edit</a-button>
<!--          <a @click="up(record.id)"><ArrowUpOutlined/></a>-->
<!--          <a-button @click="up(record.id)">Move Up</a-button>-->
          <a-button :type="record.is_valid ? 'default' : 'primary'" @click="toggleEnable(record)"> {{ record.is_valid ? 'Disable': 'Enable' }} </a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>

import {
  addNewBrand,
  getBrandList,
  updateBrand
} from '../../api/product'

// import { ref } from 'vue'
// import { ArrowUpOutlined, EditOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    slots: { customRender: 'name' }
  },
  {
    title: 'Enable',
    dataIndex: 'is_valid',
    width: '10%',
    slots: { customRender: 'is_valid' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '30%'
  }
]

export default {
  name: 'BrandList',
  components: {
    // EditOutlined,
    // ArrowUpOutlined
  },
  setup () {
    const store = useStore()
    // eslint-disable-next-line camelcase
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  data () {
    const validateNewBrandName = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input brand')
      } else {
        const item = this.brands.filter(item => item.name === value)
        if (item.length > 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(`${value} already exists`)
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      form: {
        brandName: ''
      },
      rules: {
        brandName: [
          {
            required: true,
            trigger: 'change',
            validator: validateNewBrandName
          }
        ]
      },
      columns,
      brands: [],
      editableData: {}
    }
  },

  created () {
    this.loadBrands()
  },

  methods: {
    handleAdd () {
      this.$refs.addBrandForm
        .validate().then(() => {
          const params = {
            userId: this.userId,
            brandName: this.form.brandName
          }

          console.log(params)

          addNewBrand(params).then(res => {
            if (res.code === 0) {
              message.success(`Successfully added new brand ${this.form.brandName}`)
              this.brands.push(res.data.brand)
              this.$refs.addBrandForm.resetFields()
            }
          }).catch(err => {
            console.log(err)
          })
        })
    },

    loadBrands () {
      this.$loading.show('Loading brands list...')
      getBrandList()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.brands = res.data.brands
            if (this.filters && this.filters.brandId()) {
              this.loadSpuList()
            }
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },
    onChange () {
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.brands.filter(item => item.id === key)[0])
    },

    save (key) {
      updateBrand({
        id: key,
        name: this.editableData[key].name
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.brands.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
        } else {
          message.error('Failed to change brand name, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    cancel (key) {
      delete this.editableData[key]
    },

    toggleEnable (record) {
      updateBrand({
        id: record.id,
        is_valid: record.is_valid === 1 ? 0 : 1
      }).then(res => {
        if (res.code === 0) {
          record.is_valid = record.is_valid === 1 ? 0 : 1
        } else {
          message.error('Failed to update brand status')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

.editable-row-operations a {
  margin-right: 8px;
}

</style>
