const state = {
  spuLoadingParams: localStorage.getItem('SPU_LOADING_PARAMS')
    ? JSON.parse(localStorage.getItem('SPU_LOADING_PARAMS')) || {}
    : {}
}

const mutations = {
  SET_SPU_LOADING_PARAMS: (state, info) => {
    state.spuLoadingParams = info
  }
}

const actions = {
  cacheSpuLoadingParams ({ commit }, {
    pager,
    filters,
    sorters,
    search
  }) {
    localStorage.setItem('SPU_LOADING_PARAMS', JSON.stringify({
      pager,
      filters,
      sorters,
      search
    }))
    commit('SET_SPU_LOADING_PARAMS', {
      pager,
      filters,
      sorters,
      search
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
