<template>
  <a-divider orientation="left">
    Category and spec binding
  </a-divider>

  <CategoryNavV2 ref="categorySelector" :categories="categories"
       @onCategoryChanged="categoryChanged"></CategoryNavV2>

  <a-row>
    <a-col>
      <a-select
          label="Associate new specs"
          mode="tags"
          placeholder="Please select"
          v-model:value="selectedSpecs"
          style="min-width: 300px"
      >
        <a-select-option v-for="newSpec in filterSpecs" :key="newSpec.id">
          {{ newSpec.name }}
        </a-select-option>
      </a-select>

      <a-button style="margin-left: 1rem;" type="default" @click="associateSpecs">Associate Specs</a-button>
    </a-col>
  </a-row>

  <a-table :columns="columns" :data-source="specs" rowKey="id" size="small" style="margin-top: 1rem;">
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span>
          <a-button style="margin-left: 1rem;" @click="unbind(record.csid)">Unbind</a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>

import CategoryNavV2 from '../../components/product/CategoryNavV2'

import {
  bindSpecsWithCategory,
  getSpecsByCategory,
  getCategoriesAndCountries,
  unBindSpecWithCategory
} from '../../api/product'

import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    slots: { customRender: 'name' },
    ellipsis: true
  },
  {
    title: 'Default Value',
    dataIndex: 'values',
    width: '20%',
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '30%',
    slots: { customRender: 'description' },
    ellipsis: true
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '30%'
  }
]

export default {
  name: 'CategorySpec',

  components: { CategoryNavV2 },

  setup () {
    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)
    return {
      userId
    }
  },

  created () {
    this.loadCategoryAndSpecs()
  },

  data () {
    return {
      categories: [],
      specs: [],
      currentCategoryId: 0,
      columns,
      selectedSpecs: ref([]),
      filterSpecs: []
    }
  },

  methods: {
    loadCategoryAndSpecs () {
      this.$loading.show('Loading category list...')
      getCategoriesAndCountries()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.categories = res.data.categories

            // 假如没有选中的类别，就设置第一个为选中
            if (this.currentCategoryId === 0) {
              if (this.categories.length > 0) {
                this.currentCategoryId = this.categories[0].id
              }
            }

            // 设置选中的categoryId
            this.$refs.categorySelector.setInitialCategoryId(this.currentCategoryId)
            this.loadSpecs()
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },

    categoryChanged (id) {
      if (id !== this.currentCategoryId) {
        this.currentCategoryId = id
        this.loadSpecs()
      }
    },

    loadSpecs () {
      this.$loading.show('Loading specs list...')
      getSpecsByCategory({
        category_id: this.currentCategoryId
      }).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.filterSpecs = []
          const associateList = []
          this.specs = res.data.specs
          this.specs.forEach(item => {
            associateList.push(item.id)
            if (item.default_value) {
              item.values = item.default_value.join(' / ')
            }
          })

          res.data.total_specs.forEach(item => {
            if (!associateList.includes(item.id)) {
              this.filterSpecs.push(item)
            }
          })
        }
      }).catch(err => {
        console.log(err)
        this.$loading.hide()
      })
    },

    associateSpecs () {
      if (this.selectedSpecs.length === 0) {
        message.warn('Please select specs to bind')
        return
      }

      bindSpecsWithCategory({
        creator_id: this.userId,
        category_id: this.currentCategoryId,
        specs: this.selectedSpecs
      }).then(res => {
        if (res.code === 0) {
          this.selectedSpecs = []
          this.loadSpecs()
        } else {
          message.warn('Failed to associate specs to current category, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    unbind (csid) {
      // use pcbid to improve backend performance
      unBindSpecWithCategory({
        csid
      }).then(res => {
        if (res.code === 0) {
          this.loadSpecs()
        } else {
          message.warn('Failed to unbind spec from category, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
