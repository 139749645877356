import request from '@/utils/request'

const productApi = {
  GetCategoriesGradesCountries: '/inventory/categories_grades_countries',
  GetCategoriesAndGrades: '/inventory/categories_grades',
  GetCategoriesAndCountries: '/inventory/categories_countries',
  GetBrandsByCategory: '/inventory/brands',

  GetSpuSpec: '/inventory/get_spu_spec',
  NewSku: '/inventory/new_sku',
  GetSkuList: '/inventory/sku_list',
  GetSkuInfo: '/inventory/get_sku',
  GetSpuInfo: '/inventory/get_spu',
  UpdateSku: '/inventory/update_sku',

  NewStock: '/inventory/new_stock',
  GetStockList: '/inventory/stock_list',
  GetStockInfo: '/inventory/get_stock',
  UpdateStock: '/inventory/update_stock',
  PublishStock: '/inventory/publish_stock',

  GetCategoryList: '/inventory/category_list',
  SwapCategorySortOrder: '/inventory/swap_category_order',
  UpdateCategoryName: '/inventory/update_category_name',
  UpdateCategoryStatus: '/inventory/update_category_status',
  AddNewCategory: '/inventory/new_category',

  AddNewBrand: '/inventory/new_brand',
  GetBrandList: '/inventory/brand_list',
  UpdateBrand: '/inventory/update_brand',
  SwapBrandSortOrder: '/inventory/swap_brand_order',

  BindBrandWithCategory: '/inventory/bind_category_brand',
  UnBindBrandWithCategory: '/inventory/unbind_category_brand',

  AddNewGrade: '/inventory/new_grade',
  GetGradeList: '/inventory/grade_list',
  UpdateGradeInfo: '/inventory/upgrade_grade',
  SwapGradeOrder: '/inventory/swap_grade_order',

  // category and specs
  GetSpecsByCategory: '/inventory/category_specs',
  BindSpecsWithCategory: '/inventory/category_bind_specs',
  UnBindSpecWithCategory: '/inventory/category_unbind_spec',

  UpdateSpecInfo: '/inventory/update_spec_info'
}

export function updateSpecInfo (params) {
  return request({
    url: productApi.UpdateSpecInfo,
    method: 'post',
    data: params
  })
}

export function getCategoryList (params) {
  return request({
    url: productApi.GetCategoryList,
    method: 'post',
    data: params
  })
}

export function swapGradeOrder (params) {
  return request({
    url: productApi.SwapGradeOrder,
    method: 'post',
    data: params
  })
}

export function addNewGrade (params) {
  return request({
    url: productApi.AddNewGrade,
    method: 'post',
    data: params
  })
}

export function getGradeList (params) {
  return request({
    url: productApi.GetGradeList,
    method: 'post',
    data: params
  })
}

export function updateGradeInfo (params) {
  return request({
    url: productApi.UpdateGradeInfo,
    method: 'post',
    data: params
  })
}

export function unBindBrandFromCategory (params) {
  return request({
    url: productApi.UnBindBrandWithCategory,
    method: 'post',
    data: params
  })
}

export function bindBrandWithCategory (params) {
  return request({
    url: productApi.BindBrandWithCategory,
    method: 'post',
    data: params
  })
}

export function swapBrandOrder (params) {
  return request({
    url: productApi.SwapBrandSortOrder,
    method: 'post',
    data: params
  })
}

export function addNewBrand (params) {
  return request({
    url: productApi.AddNewBrand,
    method: 'post',
    data: params
  })
}

export function getBrandList (params) {
  return request({
    url: productApi.GetBrandList,
    method: 'post',
    data: params
  })
}

export function updateBrand (params) {
  return request({
    url: productApi.UpdateBrand,
    method: 'post',
    data: params
  })
}

export function addNewCategory (params) {
  return request({
    url: productApi.AddNewCategory,
    method: 'post',
    data: params
  })
}

export function swapCategoryOrder (params) {
  return request({
    url: productApi.SwapCategorySortOrder,
    method: 'post',
    data: params
  })
}

export function updateCategoryName (params) {
  return request({
    url: productApi.UpdateCategoryName,
    method: 'post',
    data: params
  })
}

export function updateCategoryStatus (params) {
  return request({
    url: productApi.UpdateCategoryStatus,
    method: 'post',
    data: params
  })
}

export function publishStock (params) {
  return request({
    url: productApi.PublishStock,
    method: 'post',
    data: params
  })
}

export function updateStock (params) {
  return request({
    url: productApi.UpdateStock,
    method: 'post',
    data: params
  })
}

export function updateSku (params) {
  return request({
    url: productApi.UpdateSku,
    method: 'post',
    data: params
  })
}

export function getSpuInfo (params) {
  return request({
    url: productApi.GetSpuInfo,
    method: 'post',
    data: params
  })
}

export function getSkuInfo () {
  return request({
    url: productApi.GetSkuInfo,
    method: 'post'
  })
}

export function getStockInfo (params) {
  return request({
    url: productApi.GetStockInfo,
    method: 'post',
    data: params
  })
}

export function getCategoriesAndGradesAndCountries (params) {
  return request({
    url: productApi.GetCategoriesGradesCountries,
    method: 'post',
    data: params
  })
}

export function getCategoriesAndGrades () {
  return request({
    url: productApi.GetCategoriesAndGrades,
    method: 'post'
  })
}

export function getCategoriesAndCountries () {
  return request({
    url: productApi.GetCategoriesAndCountries,
    method: 'post'
  })
}

export function getStockList (params) {
  return request({
    url: productApi.GetStockList,
    method: 'post',
    data: params
  })
}

export function getSkuList (params) {
  return request({
    url: productApi.GetSkuList,
    method: 'post',
    data: params
  })
}

export function getSpecsByCategory (params) {
  return request({
    url: productApi.GetSpecsByCategory,
    method: 'post',
    data: params
  })
}

export function unBindSpecWithCategory (params) {
  return request({
    url: productApi.UnBindSpecWithCategory,
    method: 'post',
    data: params
  })
}

export function bindSpecsWithCategory (params) {
  return request({
    url: productApi.BindSpecsWithCategory,
    method: 'post',
    data: params
  })
}

export function getBrandsByCategory (params) {
  return request({
    url: productApi.GetBrandsByCategory,
    method: 'post',
    data: params
  })
}

export function getSpuSpecs (params) {
  return request({
    url: productApi.GetSpuSpec,
    method: 'post',
    data: params
  })
}

export function createNewSku (params) {
  return request({
    url: productApi.NewSku,
    method: 'post',
    data: params
  })
}

export function createNewStock (params) {
  return request({
    url: productApi.NewStock,
    method: 'post',
    data: params
  })
}
