import request from '@/utils/request'

const spuApi = {
  UpdateSpuStatus: '/spu/update_spu_status',
  UpdateSpuInfo: '/spu/update_spu',
  AddNewSpu: '/spu/new_spu',

  AddNewSpec: '/spu/new_spec',
  GetSpecList: '/spu/spec_list',
  UpdateSpecInfo: '/spu/update_spec',
  UpdateSpuSpecStatus: '/spu/update_spu_spec_status',
  GetSpuInfo: '/spu/get_spu_info',

  BindSpecWithSpu: '/spu/bind_specs',
  SwapSpecOrder: '/spu/swap_spec_order',
  RemoveSpecFromSpu: '/spu/remove_spu_spec',

  UpdateSpuSpecValues: '/spu/update_spu_spec_value',
  GetSpuList: '/spu/spu_list'
}

export function getSpuList (params) {
  return request({
    url: spuApi.GetSpuList,
    method: 'post',
    data: params
  })
}

export function updateSpuSpecValues (params) {
  return request({
    url: spuApi.UpdateSpuSpecValues,
    method: 'post',
    data: params
  })
}

export function swapSpecOrder (params) {
  return request({
    url: spuApi.SwapSpecOrder,
    method: 'post',
    data: params
  })
}

export function removeSpecFromSpu (params) {
  return request({
    url: spuApi.RemoveSpecFromSpu,
    method: 'post',
    data: params
  })
}

export function bindSpecWithSpu (params) {
  return request({
    url: spuApi.BindSpecWithSpu,
    method: 'post',
    data: params
  })
}

export function getSpuInfo (params) {
  return request({
    url: spuApi.GetSpuInfo,
    method: 'post',
    data: params
  })
}

export function updateSpuSpecStatus (params) {
  return request({
    url: spuApi.UpdateSpuSpecStatus,
    method: 'post',
    data: params
  })
}

export function addNewSpec (params) {
  return request({
    url: spuApi.AddNewSpec,
    method: 'post',
    data: params
  })
}

export function getSpecList (params) {
  return request({
    url: spuApi.GetSpecList,
    method: 'post',
    data: params
  })
}

export function updateSpecInfo (params) {
  return request({
    url: spuApi.UpdateSpecInfo,
    method: 'post',
    data: params
  })
}

export function updateSpuStatus (params) {
  return request({
    url: spuApi.UpdateSpuStatus,
    method: 'post',
    data: params
  })
}

export function updateSpuInfo (params) {
  return request({
    url: spuApi.UpdateSpuInfo,
    method: 'post',
    data: params
  })
}

export function addNewSpu (params) {
  return request({
    url: spuApi.AddNewSpu,
    method: 'post',
    data: params
  })
}
