const LoginCode = {
  SUCCEED: 0,
  USER_NON_EXIST: 1,
  WRONG_PASSWORD: 2,
  USER_INACTIVE: 3,
  USER_LOCKED: 4
}

const RegisterCode = {
  USER_EXIST: 1,
  NEED_EMAIL_VERIFICATION: 2
}

export default {
  LoginCode,
  RegisterCode
}
