<template>
  <a-divider orientation="left">
    Category List
  </a-divider>
  <a-row>
    <a-form
        ref="addCategoryForm"
        :model="form"
        :rules="rules"
    >
    <a-col style="display: flex;">
      <a-form-item hasFeedback name="categoryName">
        <a-input v-model:value="form.categoryName" placeholder="Input category name" type="text"></a-input>
      </a-form-item>
      <a-button class="editable-add-btn" @click="handleAdd" >Add Category</a-button>
    </a-col>
    </a-form>
  </a-row>

  <a-table :columns="columns" :data-source="categories" rowKey="id" size="small" style="margin-top: 1rem;"
           @change="onChange">
    <template #name="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].name"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #is_valid="{ text: is_valid }">
      <a-tag :color="is_valid ? 'green' : 'pink'">
        {{ is_valid ? 'Y' : 'N'}}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a @click="cancel(record.id)">Cancel</a>
          <!--          <a-popconfirm title="Sure to cancel?" @confirm="cancel(record.id)">-->
          <!--            <a>Cancel</a>-->
          <!--          </a-popconfirm>-->
        </span>
        <span v-else>
<!--          <a @click="edit(record.id)"><EditOutlined/></a>-->
          <a-button @click="edit(record.id)">Edit</a-button>
<!--          <a @click="up(record.id)"><ArrowUpOutlined/></a>-->
          <a-button @click="up(record.id)">Move Up</a-button>
          <a-button :type="record.is_valid ? 'default' : 'primary'" @click="toggleEnable(record)"> {{ record.is_valid ? 'Disable': 'Enable' }} </a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>

import {
  addNewCategory,
  getCategoriesAndCountries,
  swapCategoryOrder,
  updateCategoryName, updateCategoryStatus
} from '../../api/product'

// import { ref } from 'vue'
// import { ArrowUpOutlined, EditOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    slots: { customRender: 'name' }
  },
  {
    title: 'Display Order',
    dataIndex: 'sort_order',
    width: '10%',
    slots: { customRender: 'sort_order' }
  },
  {
    title: 'Enable',
    dataIndex: 'is_valid',
    width: '10%',
    slots: { customRender: 'is_valid' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '30%'
  }
]

export default {
  name: 'CategoryList',
  components: {
    // EditOutlined,
    // ArrowUpOutlined
  },
  setup () {
    const store = useStore()
    // eslint-disable-next-line camelcase
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  data () {
    const validateNewCategoryName = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input category')
      } else {
        const item = this.categories.filter(item => item.name === value)
        if (item.length > 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(`${value} already exists`)
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      form: {
        categoryName: ''
      },
      rules: {
        categoryName: [
          {
            required: true,
            trigger: 'change',
            validator: validateNewCategoryName
          }
        ]
      },
      columns,
      categories: [],
      editableData: {}
    }
  },

  created () {
    this.loadCategoryAndBrands()
  },

  methods: {
    handleAdd () {
      this.$refs.addCategoryForm
        .validate().then(() => {
          const params = {
            userId: this.userId,
            categoryName: this.form.categoryName
          }

          console.log(params)

          addNewCategory(params).then(res => {
            if (res.code === 0) {
              message.success(`Successfully added new category ${this.form.categoryName}`)
              this.categories.push(res.data.category)
              this.categories.sort((a, b) => a.sort_order - b.sort_order)
              this.$refs.addCategoryForm.resetFields()
            }
          }).catch(err => {
            console.log(err)
          })
        })
    },

    loadCategoryAndBrands () {
      this.$loading.show('Loading category list...')
      getCategoriesAndCountries()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.categories = res.data.categories
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },
    onChange () {
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.categories.filter(item => item.id === key)[0])
    },

    save (key) {
      updateCategoryName({
        id: key,
        name: this.editableData[key].name
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.categories.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
        } else {
          message.error('Failed to change category name, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    cancel (key) {
      delete this.editableData[key]
    },

    swapItem (index1, index2) {
      swapCategoryOrder([
        {
          id: this.categories[index1].id,
          sort_order: this.categories[index2].sort_order
        },
        {
          id: this.categories[index2].id,
          sort_order: this.categories[index1].sort_order
        }
      ]).then(res => {
        if (res.code === 0) {
          const idx = this.categories[index1].sort_order
          this.categories[index1].sort_order = this.categories[index2].sort_order
          this.categories[index2].sort_order = idx
          this.categories.sort((a, b) => a.sort_order - b.sort_order)
        } else {
          message.error('Can\'t update display order, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    up (key) {
      let prevItemIndex = 0
      let currentItemIndex = 0
      for (let i = 0; i < this.categories.length; ++i) {
        currentItemIndex = i
        if (this.categories[i].id === key) {
          break
        } else {
          prevItemIndex = currentItemIndex
        }
      }

      if (currentItemIndex !== 0) {
        this.swapItem(currentItemIndex, prevItemIndex)
      }
    },

    toggleEnable (record) {
      updateCategoryStatus({
        id: record.id,
        is_valid: record.is_valid === 1 ? 0 : 1
      }).then(res => {
        if (res.code === 0) {
          record.is_valid = record.is_valid === 1 ? 0 : 1
        } else {
          message.error('Failed to update brand status')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

.editable-row-operations a {
  margin-right: 8px;
}

</style>
