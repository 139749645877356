<template>
    <a-form ref="formRef" :model="specEditorForm">
      <a-form-item
          v-for="(_, index) in specEditorForm.values"
          :key="index"
          :name="['values', index]"
          :rules="{
            required: true,
            message: 'Option value can\'t be null',
            trigger: 'change',
          }"
      >
        <a-input
            v-model:value="specEditorForm.values[index]"
            placeholder="please input candidate value"
            style="width: 100%">
          <template #addonAfter>
            <UpCircleOutlined class="dynamic-up-button dynamic-button" @click="moveUp(index)"/>
            <MinusCircleOutlined class="dynamic-button" @click="removeDomain(index)" />
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button type="dashed" style="width: 100%" @click="addDomain">
          <PlusOutlined />
          Add new option
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" @click="submitForm">Save {{specEditorForm.name}}</a-button>
      </a-form-item>
</a-form>
</template>

<script>
import { MinusCircleOutlined, PlusOutlined, UpCircleOutlined } from '@ant-design/icons-vue'
import { reactive, ref } from 'vue'
import { checkIfDuplicateExists } from '../../utils/util'
import { message } from 'ant-design-vue'

export default {
  name: 'SpecEdit',
  components: {
    UpCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined
  },

  props: {
    data: Object
  },

  setup (props, context) {
    const formRef = ref()

    const specEditorForm = reactive(
      props.data
    )

    const submitForm = () => {
      formRef.value.validate()
        .then(() => {
          if (checkIfDuplicateExists(specEditorForm.values)) {
            message.error('Option values have duplicates, please remove and retry')
            return
          }
          const params = {
            id: specEditorForm.id,
            values: specEditorForm.values
          }
          context.emit('saveSpecs', params)
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    const removeDomain = index => {
      specEditorForm.values.splice(index, 1)
    }

    const moveUp = index => {
      if (index > 0) {
        const temp = specEditorForm.values[index - 1]
        specEditorForm.values[index - 1] = specEditorForm.values[index]
        specEditorForm.values[index] = temp
      }
    }

    const addDomain = () => {
      specEditorForm.values.push('')
    }

    return {
      formRef,
      specEditorForm,
      submitForm,
      removeDomain,
      addDomain,
      moveUp
    }
  }
}
</script>
<style>

.dynamic-up-button {
  margin-right: 1rem;
}

.dynamic-button {
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-button:hover {
  color: #777;
}
.dynamic-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
