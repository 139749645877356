import { ACCESS_TOKEN, LOGIN_STATE, USER_INFO } from '@/store/types'

const state = {
  loggedIn: localStorage.getItem(LOGIN_STATE) || false,
  user: JSON.parse(localStorage.getItem(USER_INFO)) || {}
}

const mutations = {
  SET_STATUS: (state, logged) => {
    state.loggedIn = logged
  },
  SET_USER: (state, user) => {
    state.user = user
  }
}

const actions = {
  loginSucceed ({ commit }, {
    token,
    logged,
    user
  }) {
    localStorage.setItem(ACCESS_TOKEN, token)
    localStorage.setItem(LOGIN_STATE, logged)
    localStorage.setItem(USER_INFO, JSON.stringify(user))
    commit('SET_STATUS', logged)
    commit('SET_USER', user)
  },

  logout ({ commit }) {
    localStorage.removeItem(ACCESS_TOKEN)
    commit('SET_STATUS', false)
    commit('SET_USER', {})
  }
}

const getters = {
  isLoggedIn: (state, getters, rootState) => {
    console.log(getters)
    console.log(rootState)
    return state.loggedIn
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
