import request from '@/utils/request'

const userApi = {
  Login: '/user/login',
  Register: '/user/register'
}

export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function registerUser (parameter) {
  return request({
    url: userApi.Register,
    method: 'post',
    data: parameter
  })
}
