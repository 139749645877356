<template>
  <div class="main">
    <a-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :scrollToFirstError="true">
      <a-row>
        <a-col span="12">
          <a-form-item hasFeedback name="firstName">
            <a-input v-model:value="form.firstName" placeholder="First Name" type="text"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item hasFeedback name="lastName">
            <a-input v-model:value="form.lastName" placeholder="Last Name" type="text"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item hasFeedback name="email">
        <a-input v-model:value="form.email" placeholder="Email" type="text"/>
      </a-form-item>

      <a-row>
        <a-col span="12">
          <a-form-item hasFeedback name="password">
            <a-input v-model:value="form.password" placeholder="Password" type="password"/>
          </a-form-item>
        </a-col>
        <a-col span="12">
          <a-form-item hasFeedback name="password2">
            <a-input v-model:value="form.password2" placeholder="Password again" type="password"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col span="12">
          <a-select
            v-model:value="form.phoneCode"
            show-search
            placeholder="Select country"
            style="width: 100%"
            :loading="loadingCountries"
          >
            <a-select-option v-for="country in countries" :value="country.phone_code" v-bind:key="country.id">{{ country.phone_code }} {{ country.name }}</a-select-option>
          </a-select>
        </a-col>
        <a-col span="12">
          <a-form-item hasFeedback name="phone">
            <a-input v-model:value="form.phone" placeholder="Phone number" type="text"/>
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item>
        <a-button class="bold" style="width: 100%" type="primary" @click="onSubmit">
          Register
        </a-button>
      </a-form-item>

      <a-form-item style="text-align: center">
        <router-link class="bold" to="/user/login">Already registered? Go to sign in</router-link>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

// import { ref } from 'vue'

import { Button, Form, Input, message } from 'ant-design-vue'
import { checkEmailFormat } from '@/utils/util'
import { registerUser } from '@/api/user'

import CODE from '@/api/codes'
import * as md5 from 'md5'
import { getCountryList } from '../../api/reer'

export default {
  name: 'Register',
  components: {
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Input.name]: Input,
    [Button.name]: Button
  },
  data () {
    const validateEmail = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input your email')
      } else {
        if (!checkEmailFormat(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Email should look like a@b.com')
        } else {
          return Promise.resolve()
        }
      }
    }

    const validatePassword = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Password again')
      } else {
        if (value !== this.form.password) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Password mismatch')
        } else {
          return Promise.resolve()
        }
      }
    }

    // const validateCountryCode = async (rule, value) => {
    //   console.log(value)
    //   if (!value || value.length === 0) {
    //     // eslint-disable-next-line prefer-promise-reject-errors
    //     return Promise.reject('Select country')
    //   } else {
    //     return Promise.resolve()
    //   }
    // }

    return {
      loadingCountries: false,
      countries: [],
      form: {
        email: '',
        password: '',
        password2: '',
        firstName: '',
        lastName: '',
        phoneCode: '',
        phone: ''
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'First Name',
            trigger: 'change'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Last Name',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            message: 'Password',
            trigger: 'change'
          },
          {
            min: 6,
            message: 'Must be at least 6 characters',
            trigger: 'change'
          }
        ],
        password2: [
          {
            required: true,
            validator: validatePassword,
            trigger: 'change'
          }
        ],
        phoneCode: [
          {
            type: 'array',
            required: true,
            message: 'Select country',
            // validator: validateCountryCode,
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Phone Number',
            trigger: 'change'
          }
        ]
      }
    }
  },

  created () {
    this.loadCountryList()
  },

  methods: {
    loadCountryList () {
      this.loadingCountries = true
      getCountryList().then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          if (this.countries && this.countries.length > 0) {
            this.form.phoneCode = this.countries[0].phone_code
          }
        }
        this.loadingCountries = false
      }).catch(err => {
        console.log(err)
      })
    },
    // filterOption (input, option) {
    //   return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    // },

    onSubmit () {
      this.$refs.ruleForm
        .validate()
        .then(() => {
          registerUser({
            email: this.form.email,
            password: md5(this.form.password),
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            phone: this.form.phoneCode + ' ' + this.form.phone
          })
            .then(res => {
              if (res.code === CODE.RegisterCode.USER_EXIST) {
                message.error(res.message)
              } else if (res.code === CODE.RegisterCode.NEED_EMAIL_VERIFICATION) {
                setTimeout(() => {
                  this.$router.push({
                    name: 'verify',
                    params: { email: this.form.email }
                  })
                }, 1000)
              } else {
                message.error(res.message)
              }
            }).catch(err => {
              message.error(err.message)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    }
  }
}
</script>

<style scoped>

.bold {
  font-weight: bold;
}

.main {
  max-width: 480px;
  margin: 5rem auto;
  padding: 0 2rem;
  text-align: start;
}

</style>
