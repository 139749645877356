<template>
  <a-divider orientation="left">
    Category and brand binding
  </a-divider>

  <CategoryNavV2 ref="categorySelector" :categories="categories"
       @onCategoryChanged="categoryChanged"></CategoryNavV2>

  <a-row>
    <a-col>
      <a-select
          label="Associate new brands"
          mode="tags"
          placeholder="Please select"
          v-model:value="selectedBrands"
          style="min-width: 300px"
      >
        <a-select-option v-for="newBrand in filterBrands" :key="newBrand.id">
          {{ newBrand.name }}
        </a-select-option>
      </a-select>

      <a-button style="margin-left: 1rem;" type="default" @click="associateBrands">Associate Brands</a-button>
    </a-col>
  </a-row>

  <a-table :columns="columns" :data-source="brands" rowKey="id" size="small" style="margin-top: 1rem;">
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span>
          <a-button @click="up(record.id)">Move Up</a-button>
          <a-button style="margin-left: 1rem;" @click="unbind(record.pcbid)">Unbind</a-button>
        </span>
      </div>
    </template>
  </a-table>
</template>

<script>

import CategoryNavV2 from '../../components/product/CategoryNavV2'
import {
  bindBrandWithCategory,
  getBrandsByCategory,
  getCategoriesAndCountries,
  swapBrandOrder,
  unBindBrandFromCategory
} from '../../api/product'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import { message } from 'ant-design-vue'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '20%',
    slots: { customRender: 'name' }
  },
  {
    title: 'Display Order',
    dataIndex: 'sort_order',
    width: '10%',
    slots: { customRender: 'sort_order' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '30%'
  }
]

export default {
  name: 'CategoryBrand',

  components: { CategoryNavV2 },

  setup () {
    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)
    return {
      userId
    }
  },

  created () {
    this.loadCategoryAndBrands()
  },

  data () {
    return {
      categories: [],
      brands: [],
      currentCategoryId: 0,
      columns,
      selectedBrands: ref([]),
      filterBrands: []
    }
  },

  methods: {
    loadCategoryAndBrands () {
      this.$loading.show('Loading category list...')
      getCategoriesAndCountries()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.categories = res.data.categories

            // 假如没有选中的类别，就设置第一个为选中
            if (this.currentCategoryId === 0) {
              if (this.categories.length > 0) {
                this.currentCategoryId = this.categories[0].id
              }
            }

            // 设置选中的categoryId
            this.$refs.categorySelector.setInitialCategoryId(this.currentCategoryId)
            this.loadBrands()
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },

    categoryChanged (id) {
      if (id !== this.currentCategoryId) {
        this.currentCategoryId = id
        this.loadBrands()
      }
    },

    loadBrands () {
      this.$loading.show('Loading brands list...')
      getBrandsByCategory({
        categoryId: this.currentCategoryId
      }).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.filterBrands = []
          const associateList = []
          this.brands = res.data.brands
          this.brands.forEach(item => {
            associateList.push(item.id)
          })

          res.data.total_brands.forEach(item => {
            if (!associateList.includes(item.id)) {
              this.filterBrands.push(item)
            }
          })
        }
      }).catch(err => {
        console.log(err)
        this.$loading.hide()
      })
    },

    swapItem (index1, index2) {
      swapBrandOrder([
        {
          category_id: this.currentCategoryId,
          brand_id: this.brands[index1].id,
          sort_order: this.brands[index2].sort_order
        },
        {
          category_id: this.currentCategoryId,
          brand_id: this.brands[index2].id,
          sort_order: this.brands[index1].sort_order
        }
      ]).then(res => {
        if (res.code === 0) {
          const idx = this.brands[index1].sort_order
          this.brands[index1].sort_order = this.brands[index2].sort_order
          this.brands[index2].sort_order = idx
          this.brands.sort((a, b) => a.sort_order - b.sort_order)
        } else if (res.code === -1) {
          message.error('Invalid arguments! please check data and retry')
        } else {
          message.error('Can\'t update display order, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    up (key) {
      let prevItemIndex = 0
      let currentItemIndex = 0
      for (let i = 0; i < this.brands.length; ++i) {
        currentItemIndex = i
        if (this.brands[i].id === key) {
          break
        } else {
          prevItemIndex = currentItemIndex
        }
      }
      if (currentItemIndex !== 0) {
        this.swapItem(currentItemIndex, prevItemIndex)
      }
    },

    associateBrands () {
      if (this.selectedBrands.length === 0) {
        message.warn('Please select brands to bind')
      }

      bindBrandWithCategory({
        user_id: this.userId,
        category_id: this.currentCategoryId,
        brands: this.selectedBrands
      }).then(res => {
        if (res.code === 0) {
          this.selectedBrands = []
          this.loadBrands()
        } else {
          message.warn('Failed to associate brands to current category, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    unbind (pcbid) {
      // use pcbid to improve backend performance
      unBindBrandFromCategory({
        pcbid: pcbid
      }).then(res => {
        if (res.code === 0) {
          this.loadBrands()
        } else {
          message.warn('Failed to unbind brand from category, please retry later')
        }
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>

</style>
