export function checkEmailFormat (email) {
  // 验证邮箱的正则表达式
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    // 合法的邮箱
    return true
  }
  return false
}

export function getInfoFromEmail (email) {
  if (email) {
    return email.split('@')[0]
  } else {
    return ''
  }
}

export function getHostFromEmail (email) {
  if (email) {
    return 'http://' + email.split('@')[1]
  } else {
    return ''
  }
}

export function isNumber (value) {
  return typeof value === 'number' && !isNaN(value)
}

export function compareString (a, b) {
  if (a.attr < b.attr) {
    return -1
  }
  if (a.attr > b.attr) {
    return 1
  }
  return 0
}

export function checkIfDuplicateExists (w) {
  return new Set(w).size !== w.length
}
