<template>
  <a-divider orientation="left">
    Spec List
  </a-divider>
  <a-row>
    <a-form
        ref="addSpecForm"
        :model="form"
        :rules="rules"
    >
      <a-col>
        <a-space>
          <a-form-item hasFeedback name="specName" required>
            <a-input v-model:value="form.specName" placeholder="Input spec name" type="text"></a-input>
          </a-form-item>
          <a-form-item hasFeedback name="specDescription">
            <a-input v-model:value="form.specDescription" placeholder="Input spec description" type="text"></a-input>
          </a-form-item>
          <a-form-item>
            <a-button class="editable-add-btn" @click="handleAdd" >Add New</a-button>
          </a-form-item>
        </a-space>
      </a-col>
    </a-form>
  </a-row>

  <a-table :columns="columns" :data-source="specs" rowKey="id" size="small"
           @change="onChange">
    <template #name="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].name"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #description="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].description"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #is_valid="{ text: is_valid }">
      <a-tag :color="is_valid ? 'green' : 'pink'">
        {{ is_valid ? 'Y' : 'N'}}
      </a-tag>
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a @click="cancel(record.id)">Cancel</a>
        </span>
        <span v-else>
          <a-space>
            <a-button @click="edit(record.id)">Edit</a-button>
            <a-button :type="record.is_valid ? 'default' : 'primary'" @click="toggleEnable(record)"> {{ record.is_valid ? 'Disable': 'Enable' }} </a-button>
          </a-space>
        </span>
      </div>
    </template>
    <template #expandedRowRender="{ record }">
      <a-row style="padding-top: 2rem;">
        <a-col :xs="24" :sm="12">
          <SpecEdit :data="record" @saveSpecs="saveSpecValues"></SpecEdit>
        </a-col>
      </a-row>
    </template>
  </a-table>
</template>

<script>

import SpecEdit from '../../components/spu/SpecEdit'

import {
  addNewSpec,
  getSpecList
} from '../../api/spu'

// import { ref } from 'vue'
// import { ArrowUpOutlined, EditOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { updateSpecInfo } from '../../api/product'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '10%',
    slots: { customRender: 'name' },
    ellipsis: true
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: '40%',
    slots: { customRender: 'description' },
    ellipsis: true
  },
  {
    title: 'Default Value',
    dataIndex: 'valueString',
    width: '20%',
    ellipsis: true
  },
  {
    title: 'Enable',
    dataIndex: 'is_valid',
    width: '10%',
    slots: { customRender: 'is_valid' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '20%'
  }
]

export default {
  name: 'SpecList',
  components: {
    SpecEdit
    // EditOutlined,
    // ArrowUpOutlined
  },
  setup () {
    const store = useStore()
    // eslint-disable-next-line camelcase
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  data () {
    const validateNewSpecName = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input spec')
      } else {
        const item = this.specs.filter(item => item.name.toLowerCase() === value.toLowerCase())
        if (item.length > 0) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject(`${value} already exists`)
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      form: {
        specName: '',
        specDescription: ''
      },
      rules: {
        specName: [
          {
            required: true,
            trigger: 'change',
            validator: validateNewSpecName
          }
        ]
      },
      columns,
      specs: [],
      editableData: {}
    }
  },

  created () {
    this.loadSpecs()
  },

  methods: {
    handleAdd () {
      this.$refs.addSpecForm.validate().then(() => {
        const params = {
          user_id: this.userId,
          name: this.form.specName,
          description: this.form.specDescription
        }

        addNewSpec(params).then(res => {
          if (res.code === 0) {
            message.success(`Successfully added new spec ${this.form.gradeName}`)
            this.$refs.addSpecForm.resetFields()
            this.loadSpecs()
          }
        }).catch(err => {
          console.log(err)
        })
      })
    },

    loadSpecs () {
      this.$loading.show('Loading spec list...')
      getSpecList()
        .then(res => {
          this.$loading.hide()
          if (res.code === 0) {
            this.specs = res.data.specs
            this.specs.forEach(item => {
              if (item.default_value) {
                item.valueString = item.default_value.join(' / ')
                item.values = item.default_value
              } else {
                item.values = []
              }
            })
          }
        }).catch(err => {
          console.log(err)
          this.$loading.hide()
        })
    },
    onChange () {
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.specs.filter(item => item.id === key)[0])
    },

    save (key) {
      updateSpecInfo({
        id: key,
        name: this.editableData[key].name,
        description: this.editableData[key].description,
        modifier_id: this.userId
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.specs.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
          message.success('Successfully update spec information')
        } else {
          message.error('Failed to change grade info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    cancel (key) {
      delete this.editableData[key]
    },

    saveSpecValues (params) {
      params.modifier_id = this.userId
      updateSpecInfo(params).then(res => {
        if (res.code === 0) {
          message.info('Spec values updated successfully')
          this.loadSpecs()
        }
      })
    },

    toggleEnable (record) {
      const params = {
        id: record.id,
        is_valid: record.is_valid === 1 ? 0 : 1,
        modifier_id: this.userId
      }
      updateSpecInfo(params).then(res => {
        if (res.code === 0) {
          record.is_valid = params.is_valid
          if (record.is_valid === 0) {
            message.warn('Spec status is set to inactive')
          } else {
            message.success('Spec status is successfully set to active')
          }
        } else {
          message.error('Failed to update spec status, please retry later')
        }
      })
    }
  }
}
</script>

<style scoped>

.editable-row-operations a {
  margin-right: 8px;
}

</style>
