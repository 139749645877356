<template>
  <div class="container px-0">
    <div class="header">
      <a href="/">
        <img alt="logo" class="logo" src="~@/assets/logo.svg">
        <span class="title"><strong style="color: black;">REER</strong> Global</span>
      </a>
    </div>
    <router-view/>
    <div class="footer">
      <div class="links">
        <a href="_self">Help</a>
        <a href="_self">Privacy</a>
        <a href="_self">Terms</a>
      </div>
      <div class="copyright">
        Copyright &copy; 2019-2021 All rights reserved. <br> REER Electronics L.L.C.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserLayout'
}
</script>

<style lang="scss" scoped>

//#userLayout.user-layout-wrapper {

//height: 100%;

.container {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background: #f0f2f5 url(~@/assets/world.svg) no-repeat 100%;
  background-size: 100%;

  .header {
    padding-top: 2rem;
    height: 6rem;
    line-height: 6rem;
    text-align: center;

    .logo {
      height: 84px;
      vertical-align: top;
      margin-right: 16px;
      border-style: none;
    }

    .title {
      font-size: 33px;
      color: rgba(0, 0, 0, .85);
      font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
      font-weight: 600;
      position: relative;
      top: 2px;
    }
  }

  .desc {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    margin-top: 12px;
    margin-bottom: 40px;

    span {
      strong {
        color: black;
      }
    }
  }

  .footer {
    position: absolute;
    width: 100%;
    bottom: 3rem;
    text-align: center;

    .links {
      margin-bottom: 8px;
      font-size: 14px;

      a {
        color: rgba(0, 0, 0, 0.45);
        transition: all 0.3s;

        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    .copyright {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      line-height: 2rem;
    }
  }
}

</style>
