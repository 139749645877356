<template>
  <a-row>
    <a-col>
      <div class="slide-menu-wrapper">
        <div class="slide-menu-container">
          <div class="slide-menu-item" v-for="category in categories" v-bind:key="category.id"
               :class="{'active': selectedId === category.id}"
               @click="selectCategory(category.id)">
            {{ category.name }}
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>

export default {
  name: 'CategoryNavV2',

  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      selectedId: undefined
    }
  },

  emits: ['onCategoryChanged'],

  methods: {
    setInitialCategoryId (id) {
      this.selectedId = id
    },
    selectCategory (id) {
      if (this.selectedId !== id) {
        this.selectedId = id
        this.$emit('onCategoryChanged', id)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.slide-menu-wrapper {
  padding: 0.6rem 0;
  margin-top: 1px;
  background: white;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  height: 4rem;

  .slide-menu-container {
    display: inline-block;
    margin: 0.6rem 0;

    .slide-menu-item {
      display: inline;
      margin: 0 0.8rem;
      white-space: nowrap;
      cursor: pointer;
      border: 1px solid #F1651A;
      padding: 0.2rem 0.5rem;
      color: #777;

      &.active {
        border: 1px solid #de350b;
        background: #de350b;
        color: white;
      }
    }
  }
}

</style>
